.navbar {
    background-color: #ffffff;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 48px;
    margin: 10px;
    max-width: 600px;
    overflow-y: auto;
}

.menu-bars {
    margin-left: 1.5rem;
    font-size: 1.5rem;
    text-decoration: none;
}

.app-name {
    margin-left: 0rem;
    font-size: 1.5rem;
    color: black;
    position:absolute;
    margin-bottom: 30px;
    left:50%;
    transform: translateX(-50%);
}

.nav-menu {
    background: linear-gradient(to top, #fec4b4, #FFF1ED);
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: left 350ms;
}

.nav-menu.active {
    left: 0;
    transition: left 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-weight: bold;
    border-radius: 4px;
}

.nav-text a:hover {
    background-color: #FF7043;
    border-radius: 24px;
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    background-color: #FFF1ED;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 2rem;
    margin: 0px;
    padding-left: 2rem;
    padding-top: 30px;
}

span {
    margin-left: 16px;
}

.logout-button {
    width: 100%;
    padding: 10px 20px;
    background-color: #FF7043;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    border-radius: 24px;
}

.logout-button:hover {
    background-color: #c0392b;
}

.login-button {
    width: 100%;
    padding: 10px 20px;
    background-color: #FF7043;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    border-radius: 24px;
}

.login-button:hover {
    background-color: #c0392b;
}

@media (min-width: 768px) {
    .navbar {
        width: 70%; /* Set width to 70% for larger screens */
        margin-left: auto; /* Align to right */
        margin-right: auto; /* Align to left */
    }
    
}
