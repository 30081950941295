/* WelcomePage.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.welcome-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    background: linear-gradient(to top , #fec4b4, #FFF1ED);

}

.h2-text {
    color: black;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    display: block;
  }
  

h3 {
    margin: 20px;
    font-size: 2rem;
    margin-top: 30px;
}

h6 {
    color: #FF7043;
    margin: 10px;
    font-size: 2rem;
    margin-top: 10px;
}

h5{
    color: #FF7043;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 10px;
}

p {
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 1.1rem;
    margin: 10px 0;
}

button {
    background-color: #FF7043; /* Hot pink */
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 24px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #c0392b; /* Deeper pink */
}

.div-how-it-works{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:space-evenly;
}

.card-how-it-works{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* This will distribute the items evenly */
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    height: auto; /* Set a fixed height */
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.features-div-welcome-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* This will distribute the items evenly */
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    height: auto; /* Set a fixed height */
    box-shadow: 0 2px 50px rgba(0, 0, 0, 0.1);
    margin: 30px;
}


@media (min-width: 768px) {
    .div-how-it-works{
        flex-direction: row;
    }

    .card-how-it-works {
        width: 30%;
        height: auto; /* Remove fixed height on larger screens if needed */
        margin: 10px; /* Add margin to create space between cards */
    }
 }
