/* PoliciesPage.css */
.policies-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    text-align: center;
  }
  
  .section-title {
    font-size: 24px;
    margin-top: 20px;
  }
  
  .policy-link {
    display: block;
    margin-bottom: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .policy-link:hover {
    text-decoration: underline;
  }
  