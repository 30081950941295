.container-custom-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center child elements horizontally */
}

.div-button-custom-alert{
    display: flex;
    flex-direction: row;
}

.close-button-custom-alert {
    background-color: #FF7043;
    width: auto;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    margin: 10px; /* Add margin to ensure spacing */
}

.close-button-custom-alert:hover {
    background-color: #c0392b;
}

.yes-button-custom-alert{
    background-color: #22d351;
    width: auto;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    margin: 10px;
}

.yes-button-custom-alert:hover{
    background-color: #09862a;
}

.text-custom-alert {
    margin: 10px;
    color: black;
    text-align: center; /* Center text within the paragraph */
}
