/* Parent container styling to center the previous-code-div-container */
.centering-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Previous code div container */
.previous-code-div-container {
  background-color: #FFFFFF;
  border-radius: 24px;
  margin: 20px;
  padding: 20px;
  height: auto;
  max-width: 600px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Previous code card */
.previous-code-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previous-codes-ul{
  list-style-type: none; /* Remove default list styling */
    padding: 0; 
    width: 100%;
    
}
/* List item styles */
.previous-codes-li {
  display: flex; /* Use flexbox layout */
  justify-content: space-between; /* Align items to the start */
  align-items: center; /* Center items vertically */
  padding: 10px;
  width: 100%;
}

.previous-codes-li a {
   /* Allow link to take remaining space */
  text-align: right; /* Align link text to the left */
}

.previous-codes-li:nth-child(even) {
  background-color: #FFF; 
  border-radius: 8px;
  padding: 10px;
}

.previous-codes-li:nth-child(odd) {
  border-radius: 8px;
  padding:10px;
  background-color: #FFF1ED; 
}

.title-previous-code{
    color: black;
    font-weight: bold;
    font-size: 16;
    text-align: center;
    margin: 10px;
}

