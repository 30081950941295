
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  
}

body {
  background-color: #FFF1ED; /* Set your desired background color */
}

