
.link-code-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 24px;
    margin: 10px;
    padding: 10px;
    height: auto;
    max-width: 600px;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
}

.input-link-code-card{
    background-color: #F1DFDA;
    width: 100%;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    cursor: text;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.input-link-code-card::placeholder {
    font-size: 1.1rem; 
    font-weight: normal;
}

.button-link-code-card{
    background-color: #FF7043;
    width: 60%;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.button-link-code-card:hover{
    background-color: #c0392b;
}

.text-link-code-card{
    color: black;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
}

.blur-background {
    filter: blur(5px);
    pointer-events: none; /* Disable interactions with the blurred content */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}