
.div-container-dashboard {
    background: linear-gradient(to top, #fec4b4, #FFF1ED);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.dashboard-loading-text{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    height: 100%; 
}


.button-container {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin: 10px 0; /* Add margin for spacing */
}

.add-useful-link-button {
    background-color: #070707;
    color: #fff;
    padding: 10px 20px;
    margin: 10px;
    border: none;
    cursor: pointer;
    border-radius: 24px;
    transition: background-color 0.3s;
}

.add-useful-link-button:hover {
    background-color: #333333;
}



@media (min-width: 768px) {
    .div-container-dashboard {
        align-items: center;
        /* Center items horizontally on larger screens */
    }

    .div-container-dashboard>* {
        width: 70%;
        /* Set the width to 70% on laptops */
    }
}