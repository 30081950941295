.div-home-page{
   display: flex;
   flex-direction: column;
   background: linear-gradient(to top, #fec4b4, #FFF1ED);
   min-height: 100vh;

}

@media (min-width: 768px) {
    .div-home-page {
       align-items: center; /* Center items horizontally on larger screens */
    }
 
    .div-home-page > * {
       width: 70%; /* Set the width to 70% on laptops */
    }
 }