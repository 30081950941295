.profile-card{
    margin: 10px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 600px;
}

.profile-card .profile-card-image{
    width: 100px;
    height: 100px;
    border-radius: 80px;
    padding: 10px;
   object-fit: cover; 
}

.profile-card .profile-card-text{
    font-size: 1.5rem;
    padding: 5px;
}