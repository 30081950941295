.useful-link-page-container{
    display: flex;
   flex-direction: column;
   min-height: 100vh;
   max-width: 600px;
   align-items: center;
   margin: 10px;
}

.div-add-usefull-link-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 24px;
    width: 100%;
    margin: 20px;
    padding: 20px;
    height: auto;
    max-width: 600px;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
}

.div-prevous-usefull-link-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 24px;
    width: 100%;
    margin: 20px;
    padding: 20px;
    height: auto;
    max-width: 600px;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
}

.input-add-link-title{
    background-color: #F1DFDA;
    width: 100%;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    margin: 20px;
    cursor: text;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.input-add-link-title::placeholder{
    font-size: 1.1rem; 
    font-weight: normal;
}

.input-add-link-url{
    background-color: #F1DFDA;
    width: 100%;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    margin: 20px;
    cursor: text;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.input-add-link-url::placeholder{
    font-size: 1.1rem; 
    font-weight: normal;
}

.button-add-link{
    background-color: #FF7043;
    width: 60%;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.button-add-link:hover{
    background-color: #c0392b;
}

.text-useful-links{
    color: black;
    font-weight: 300;
    font-size: 16;
    text-align: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.previous-links-ul{
    list-style-type: none; /* Remove default list styling */
    padding: 0; 
    width: 100%;
}

.previous-links-li {
    display: flex; /* Use flexbox layout */
    justify-content: space-between; /* Align items to the start */
    align-items: center; /* Center items vertically */
    padding: 10px;
    width: 100%;
  }
  
  .previous-links-li a {
     /* Allow link to take remaining space */
    text-align: left; /* Align link text to the left */
    color: black;
    padding: 10px;
  }
  
  .previous-links-li:nth-child(even) {
    background-color: #FFF; 
    border-radius: 8px;
    padding: 10px;
  }
  
  .previous-links-li:nth-child(odd) {
    border-radius: 8px;
    padding:10px;
    background-color: #FFF1ED; 
  }

  .delete-button-usefull-link-page{
    background-color: #FF7043;
    border-radius: 48px;
    font-size: 2rem;
    color: #FFFFFF;
    padding: 4px;
  }

  .delete-button-usefull-link-page:hover{
    cursor: pointer;
    background-color: #c0392b;
  }

@media (min-width: 768px) {
    .useful-link-page-container {
        margin: 0 auto;
        /* Center items horizontally on larger screens */
    }
}