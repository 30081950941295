
.contact-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.contact-page-container h2 {
    margin-bottom: 20px;
    font-size: 32px;
    color: #333;
}

.contact-page-container p {
    font-size: 18px;
    margin-bottom: 10px;
    color: #555;
    text-align: center;
}

.contact-email {
    font-size: 20px;
    color: #007bff;
    text-decoration: none;
    margin-bottom: 20px;
}

.contact-email:hover {
    text-decoration: underline;
}
