.div-container-register-user-details {
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-user-details-card{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 24px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
}

.username-input{
    background-color: #F1DFDA;
    width: 100%;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    margin: 20px;
    cursor: text;
    font-size: 1rem;
    font-weight: bold;
    color: black;
}

.username-input ::placeholder{
    font-weight: normal;
}

.fullname-input{
    background-color: #F1DFDA;
    width: 100%;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    margin: 20px;
    cursor: text;
    font-size: 1rem;
    font-weight: bold;
    color: black;
}

.social-url-input ::placeholder{
    font-weight: normal;
}

.submit-username-and-url-button{
    background-color: #FF7043;
    width: 60%;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.image-chooser-register-user-details{
    width: 100px;
    height: auto;
    padding: 20px;
}
