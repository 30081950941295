.div-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-with-google-button{
    background-color: #FF7043;
    width: 40%;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.login-with-google-button:hover{
    background-color: #c0392b;
}