.generate-new-code-div-container {
    display: flex;
    justify-content: center;
    align-items: center;
}


.generate-new-code-card {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 24px;
    margin: 20px;
    padding: 20px;
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);
}

.input-generate-new-code{
    background-color: #F1DFDA;
    width: 100%;
    height: 60px;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    margin: 20px;
    cursor: text;
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
}

.input-generate-new-code::placeholder {
    font-size: 1.1rem; 
    font-weight: normal;
}

.button-generate-new-code{
    background-color: #FF7043;
    width: 60%;
    height: auto;
    border: none;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    
}

.button-generate-new-code:hover{
    background-color: #c0392b;
}

.text-generate-new-code{
    color: black;
    font-size: 16;
    text-align: center;
}

.bio-link-hint-text{
    color: gray;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}