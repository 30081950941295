.div-open-in-app-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
  height: auto;
  max-width: 600px;
}


.useful-links-ul {
  list-style-type: none;
  padding: 0;
  width: 100%;

}
.useful-links-ul a{
  text-decoration: none;
}

/* List item styles */
.useful-links-li {
  display: flex;
  /* Use flexbox layout */
  justify-content: center;
  /* Align items to the start */
  align-items: center;
  /* Center items vertically */
  text-align: center;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 48px;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  font-size: 1.1rem;
  color: black;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;  
  padding-left: 15px;
  padding-right: 15px;

}

.useful-links-li:hover{
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: scale(1.01); 
}